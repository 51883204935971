'use strict'

require('../scss/app.scss');

import aos from 'aos';
import $ from 'jquery';
import Swiper from 'swiper';

aos.init({
    offset: 120, // offset (in px) from the original trigger point
    delay: 100, // values from 0 to 3000, with step 50ms
    duration: 500, // values from 0 to 3000, with step 50ms
    easing: 'ease-out-back', // default easing for AOS animations is 'ease'
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-center', // defines which position of the element regarding to window
    disable: 'mobile'
});

$(document).ready(function() {

    $('.card').click(function(){
        $(this).toggleClass('flipped');
    });

    $(".menu").click(function(){
        $("#nav").toggleClass("open");
    });


    $('#toggle').click(function() {
        $(this).toggleClass('active');
        $('#overlay').toggleClass('open');
    });

    /*******************************************************************************************************************/

    // var header = $(".nav.fixed");
    //
    // var scroll = $(window).scrollTop();
    //
    // if (scroll >= 30) {
    //     header.addClass("scrolled");
    // } else {
    //     header.removeClass("scrolled");
    // }
    /*******************************************************************************************************************/
    //
    // $('.readmore').click(function() {
    //     $('.content').toggleClass('full');
    //     $('.readmore').toggle();
    // });
    // $('.closebttn').click(function() {
    //     $('.content').removeClass('full');
    //     $('.readmore').toggle();
    // });
});


// $(window).scroll(function() {
//
//     var header = $(".nav.fixed");
//
//     var scroll = $(window).scrollTop();
//
//     if (scroll >= 30) {
//         header.addClass("scrolled");
//     } else {
//         header.removeClass("scrolled");
//     }
// });

var swiper = new Swiper('.swiper-container', {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});